import React from 'react'
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';

const RemarkFormatter = ({ content, className }) => {
    const formattedContent = remark().use(recommended).use(remarkHtml).processSync(content).toString();

     return (
        <div className={className} dangerouslySetInnerHTML={{ __html: formattedContent }} />
    )
}

export default RemarkFormatter